import {Heading} from '../components/Heading.js'
import {Typography} from '@mui/material'

export const About = () => (
	<>
		<Heading variant='h3'>
			Chester Comedy Corner
		</Heading>
		
		<Typography align='center'>
			Our comedy nights feature the funniest stand-up acts of the UK and international circuits, many of whom have performed on shows such as Live at The Apollo and Britain's Got Talent.
		</Typography>
		<br/>
		<Typography align='center'>
			We take pride in our warm and cosy atmosphere, so prepare yourself for a great night at Chester Comedy Corner!
		</Typography>
	</>
)