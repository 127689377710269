import {useState} from 'react'
import {theme} from '../providers/theme'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Box,
	List,
	ListItem,
	ListItemText
} from '@mui/material'
import {ExpandMore} from '@mui/icons-material'
import {ExternalLink} from './ExternalLink.js'

const Item = ({expanded, handleChange, id, title, children}) => {
	return (
		<Accordion
			expanded={expanded === id}
			onChange={handleChange(id)}
			sx={{
				...(expanded === id) && {
					border: `2px solid ${theme.palette.primary.main}`,
					borderRadius: '8px'
				},
			}}
			variant='outlined'
		>
			<AccordionSummary
				expandIcon={<ExpandMore sx={{ color: theme.palette.primary.main }} />}
				aria-controls={`${id}-content`}
				id={`${id}-header`}
			>
				{title}
			</AccordionSummary>
			<AccordionDetails>
				{children}
			</AccordionDetails>
		</Accordion >
	)
}

const infoItems = [{
	id: 'parking',
	title: 'Parking',
	content: <>
		There is an <ExternalLink href='https://www.google.co.uk/maps/@53.1972486,-2.882859,3a,75y,55.08h,82.26t/data=!3m6!1e1!3m4!1s_HCiYaIs-MVn4Do1_T2vpQ!2e0!7i13312!8i6656'>NCP car park</ExternalLink> directly across the road from the venue.
	</>
	
},{
	id: 'age',
	title: 'Age Restriction',
	content: <>
		All of our events are 16+, however anyone below the age of 18 must be accompanied by an adult. There may also be some strong language and jokes that are not for the easily offended.
	</>
},{
	id: 'entry',
	title: 'Entry',
	content: <>
		The entry fees for Chester Comedy Corner are as follows:<br/>
		<List dense>
			<ListItem>
				<ListItemText>
					Students: £5
				</ListItemText>
			</ListItem>
			<ListItem>
				<ListItemText>
					Tickets: £10
				</ListItemText>
			</ListItem>
			<ListItem>
				<ListItemText>
					On the door: £13
				</ListItemText>
			</ListItem>
		</List>
	</>
},{
	id: 'cloakroom',
	title: 'Cloakroom',
	content: <>
		There is a cloakroom at the venue.
	</>
},{
	id: 'accessibility',
	title: 'Accessibility',
	content: <>
		It's important to us that everybody has the best experience they can whilst at our events. The Live Rooms is accessible and wheelchair friendly.<br/>We also offer a free personal assistant pass per ticket holder, which can be booked directly with the venue. Personal assistant pass holders should be able to meet the needs of, and assist, the person they are accompanying at the event.<br/><br/>To book a personal assistant pass or to request any other assistance, please get in touch with us <ExternalLink href='https://theliverooms.com/accessform'>here</ExternalLink>.
	</>
},{
	id: 'seating',
	title: 'Seating',
	content: <>
		All Chester Comedy Corner events are fully seated.
	</>
},{
	id: 'bar',
	title: 'Bar',
	content: <>
		The venue has a broad range of cask ales, draught, spirits, wines & soft drinks.
	</>
},{
	id: 'programme',
	title: 'Programme',
	content: <>
		<List dense>
			<ListItem>
				<ListItemText>
					Doors: 19:30
				</ListItemText>
			</ListItem>
			<ListItem>
				<ListItemText>
					Show begins: 20:00
				</ListItemText>
			</ListItem>
			<ListItem>
				<ListItemText>
					Curfew: 23:00
				</ListItemText>
			</ListItem>
		</List>
	</>
},{
	id: 'lost-property',
	title: 'Lost Property',
	content: <>
		Please contact a member of staff at the venue if you have any enquiries regarding lost or found items. Alternatively, you can get in touch with us via our contact form.
	</>
}]

export const InfoPanels = () => {
	const [expanded, setExpanded] = useState(false)
	const handleChange = panel => (event, isExpanded) => setExpanded(isExpanded ? panel : false)

	return (
		<Box>
			{infoItems.map(({id, title, content}) => (
				<Item
					expanded={expanded}
					handleChange={handleChange}
					key={id}
					id={id}
					title={title}
				>
					<Typography variant='body2'>
						{content}
					</Typography>
				</Item>
			))}
		</Box>
	)
}