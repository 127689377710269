import {Heading} from '../components/Heading.js'
import {ContactForm} from '../components/ContactForm.js'
import {InfoPanels} from '../components/InfoPanels.js'

import {
	Box,
	Unstable_Grid2 as Grid
} from '@mui/material'

export const Info = () => (
	<Grid container spacing={6} disableEqualOverflow>
		<Grid xs={12} sm={12} md={4}>
			<Heading variant='h3'>
				Finding Us
			</Heading>
			<Box>
				<iframe title='The Live Rooms map' width='100%' height='400px' frameBorder='0' src='https://www.google.com/maps/embed/v1/place?key=AIzaSyAYs-lQDVJRzTjSEvkTHfd8CbvcBLlEKgE&language=en-GB&q=The+Live+Rooms,+1+Station+Road,+Chester,+CH1+3DR&maptype=roadmap&zoom=18' />
			</Box>
		</Grid>

		<Grid xs={12} sm={12} md={4}>
			<Heading variant='h3'>
				Contact
			</Heading>
			<Box>
				<ContactForm />
			</Box>
		</Grid>

		<Grid xs={12} sm={12} md={4}>
			<Heading variant='h3'>
				Information
			</Heading>
			<Box>
				<InfoPanels />
			</Box>
		</Grid>
	</Grid>
)