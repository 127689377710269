import {createTheme} from '@mui/material/styles'

export const theme = createTheme({
	typography: {
		fontFamily: 'ccc',
		button: {
			fontWeight: 'bold'
		},
		h1: {
			fontWeight: 'bold',
			letterSpacing: 1
		},
		h2: {
			fontWeight: 'bold',
			letterSpacing: 1
		}
	},
	palette: {
		mode: 'dark',
		background: {
			default: '#222222',
			alternate: '#333333'
		},
		text: {
			primary: '#eeeeee'
		},
		primary: {
			main: '#9cb5ff'
		},
		success: {
			main: '#9dfc99'
		},
		error: {
			main: '#f44336'
		},
		contrastThreshold: 4.5
	},
	components: {
		MuiLink: {
			defaultProps: {
				underline: 'hover'
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					'&:hover': {
						backgroundColor: '#e6efff'
					}
				}
			}
		}
	}
})

export const scrollbarStyles = {
	"*::-webkit-scrollbar-track": {backgroundColor: '#111111'},
	"*::-webkit-scrollbar-thumb": {backgroundColor: '#9cb5ff', borderRadius: '8px'},
	"*::-webkit-scrollbar-corner": {backgroundColor: '#111111'},
	"*::-webkit-scrollbar": {width: '.6rem'}
}

export const globalStyles = {
	...scrollbarStyles
}

theme.typography.h3 = {
	letterSpacing: 1,
	[theme.breakpoints.down('lg')]: {
		fontSize: '2.5rem'
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: '3rem'
	}
}