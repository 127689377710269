import {theme} from '../providers/theme'
import {
	Box,
	Container
} from '@mui/material'

export const Section = ({children, alternate = false, ...props}) => (
	<Box {...props} sx={{
		pt: 8,
		pb: 12,
		background: alternate ? theme.palette.background.alternate : theme.palette.background.primary,
		...props.sx
	}}>
		<Container maxWidth='xl'>
			{children}
		</Container>
	</Box>
)