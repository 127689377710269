import {theme} from '../providers/theme'
import {
	Box,
	Typography,
	IconButton,
	useMediaQuery
} from '@mui/material'
import {ExpandMore} from '@mui/icons-material'

export const Header = () => {
	const handleScroll = () => {
		document.getElementById('about').scrollIntoView({
			behavior: 'smooth'
		})
	}
	const small = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Box sx={{
			display: 'flex',
			alignItems: 'stretch',
			alignContent: 'stretch',
			height: '100vh',
			padding: 2,
			background: "url(curtains.jpg) no-repeat fixed center",
			backgroundSize: 'cover',
			flexDirection: 'column'
		}}>
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1
			}}>
				<Box sx={{
					display: 'flex',
					justifyContent: 'space-between',
					flexGrow: 1,
				}}>	
					<Box>
						<img
							src='/logo.png'
							alt='Chester Comedy Corner'
							style={{
								maxWidth: 'min(100%, 400px)'
							}}
						/>
					</Box>
				</Box>

				<Box sx={{
					alignSelf: 'center',
					flexGrow: 1
				}}>
					<Typography
						variant='h2'
						align='center'
						sx={{
							fontSize: small ? '8vmin' : '5.10204vmin'
						}}
					>
						Chester's new monthly comedy night at The Live Rooms
					</Typography>
				</Box>

				<Box sx={{
					display: 'flex',
					alignSelf: 'center',
					alignItems: 'flex-end',
					flexGrow: 1
				}}>
					<Box>
						<IconButton aria-label='scroll' onClick={handleScroll}>
							<ExpandMore sx={{color: theme.palette.primary.main}} fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}