import {theme} from '../providers/theme'
import {
	Box,
	Container,
	Typography
} from '@mui/material'

import {SocialIcons} from '../components/SocialIcons'

export const Footer = () => {
	const now = new Date()
	return (
		<Box component='footer' sx={{
			padding: 4,
			background: theme.palette.background.alternate
		}}>
			<Container maxWidth='xl'>
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}>
					<Box>
						<Typography variant='subtitle1' sx={{
							fontSize: '.7rem',
							color: theme.palette.grey[300]
						}}>
							The Live Rooms, 1 Station Road, Chester, CH1 3DR<br/>
							© Copyright {now.getFullYear()}
						</Typography>
					</Box>
					<Box>
						<SocialIcons />
					</Box>
				</Box>
			</Container>
		</Box>
	)
}