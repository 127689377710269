import CssBaseline from '@mui/material/CssBaseline'
import {ThemeProvider} from '@mui/material/styles'
import GlobalStyles from '@mui/material/GlobalStyles'
import '@fontsource/pt-sans'

import {theme, globalStyles} from './providers/theme'
import {Section} from './sections/Section.js'
import {Header} from './sections/Header.js'
import {About} from './sections/About.js'
import {UpcomingEvents} from './sections/UpcomingEvents.js'
import {Info} from './sections/Info.js'
import {Footer} from './sections/Footer.js'

import {Container} from '@mui/material'

const App = () => {
	document.title = 'Chester Comedy Corner'
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles styles={globalStyles} />
			<CssBaseline />

			<Header />
		
			<Section id='about'>
				<Container maxWidth='xl'>
					<About />
				</Container>
			</Section>

			<Section id='upcoming-events' sx={{
				minHeight: '100vh',
				background: "url(stage.jpg) no-repeat fixed center",
				backgroundSize: 'cover',
				flexDirection: 'column'
			}}>
				<Container maxWidth='xl'>
					<UpcomingEvents />
				</Container>
			</Section>

			<Section id='info' sx={{
				minHeight: `calc(100vh - ${theme.spacing(14)})`,
			}}>
				<Container maxWidth='xl'>
					<Info />
				</Container>
			</Section>

			<Footer />
		</ThemeProvider>
	)
}

export default App