import {Events} from '../components/Events.js'
import {Heading} from '../components/Heading.js'

export const UpcomingEvents = () => (
	<>
		<Heading
			variant='h3'
			id='upcoming-events-heading'
		>
			Upcoming Events
		</Heading>
		<Events />
	</>
)