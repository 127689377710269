import {Divider} from '../components/Divider.js'
import {Typography} from '@mui/material'

export const Heading = ({
	align = 'center',
	variant,
	children,
	dividerProps,
	...props
}) => {
	return (<>
		<Typography
			variant={variant}
			align={align}
			sx={{
				mb: 6,
				pt: 4,
				...props.sx
			}}
			{...props}
		>
			{children}
		</Typography>
		<Divider
			align={align}
			{...dividerProps}
		/>
	</>)
}
