import {theme} from '../providers/theme'
import {Box} from '@mui/material'

export const Divider = ({
	width = '300px',
	height = '4px',
	background = theme.palette.primary.main,
	align = 'center',
	...props
}) => (
	<Box {...props} sx={{
		width,
		height,
		background,
		mb: 6,
		...(align === 'center') && {mx: 'auto'},
		borderRadius: '12px',
		maxWidth: '100%',
		...props.sx
	}} />
)