import {theme} from '../providers/theme'
import {
	Box,
	Link,
	Stack,
	IconButton
} from '@mui/material'

import {
	Facebook,
	Twitter
 } from '@mui/icons-material';

const socials = [{
	href: 'https://www.facebook.com/chestercomedycorner',
	icon: <Facebook />
},{
	href: 'https://www.twitter.com/comedychester',
	icon: <Twitter />
}]

export const SocialIcons = ({
	color = theme.palette.primary.main,
	size = 'large',
	...props
}) => {
	return (
		<Box {...props}>
			<Stack direction='row'>
				{socials.map(({href, icon}, i) => (
					<Link
						key={`social-${i}`}
						href={href}
						target='_blank'
						rel='noopener noreferrer'
					>
						<IconButton
							size={size}
							sx={{color}}
						>
							{icon}
						</IconButton>
					</Link>
				))}
			</Stack>
		</Box>
	)
}